.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
}

.scannerContainer {
  width: 400px;
  height: 400px;
}
