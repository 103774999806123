.button-disconnect-container {
  display: grid;
  position: relative;
  grid-template-areas:
    "marty-name marty-name marty-image"
    "marty-battery marty-signal marty-image"
    "disconnect-image disconnect-image marty-image";

  grid-template-columns: 0.3fr 0.3fr 0.4fr;
  overflow: hidden;
  padding: 0.5rem;
  padding-left: 1rem;
}

.button-disconnect-title {
  font-size: 1.8rem;
  grid-area: marty-name;
  white-space: nowrap;
}

.button-disconnect-battery-container {
  grid-area: marty-battery;
  /* padding: 1rem; */
  height: 100%;
  align-self: end;
}

.web-app-button-disconnect-signal-container {
  position: relative;
  grid-area: marty-signal;
  padding: 0.5rem;
  padding-bottom: 0.8rem;
  display: grid;
  justify-items: end
}

.button-disconnect-disconnect-btn-container {
  grid-area: disconnect-image;
  cursor: pointer;
  align-self: center;
  justify-self: center;
  padding-top: .2rem;
}

.button-disconnect-disconnect-btn-container,
.button-disconnect-disconnect-btn-container svg {
  width: 80%;
}



.button-disconnect-marty-container {
  grid-area: marty-image;
  align-self: end;
  transform: translateY(10px) translateX(5px);
}

.button-disconnect-signal-na {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 2rem;
  z-index: 1;
  background-image: linear-gradient(rgba(0,0,0, .7), rgba(0,0,0, .7));
  border-radius: 50%;
}
