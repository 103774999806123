.addons-screen-container {
    display: grid;
    justify-items: center;
    padding: 1rem;
}

.addons-screen-logo-container {
    margin-bottom: 5rem;
}

.addons-screen-title {
    font-family: "BigNoodleTitling Regular";
    color: var(--colour-BLACK);
    margin-bottom: 1rem;
}

.addons-screen-paragraph {
    color: var(--colour-GREY_3);
}

.addons-screen-addons-list-container {
    width: 100%;
    margin-top: 3rem;
}

.addons-screen-notice {
    color: var(--colour-RED);
    font-size: 2rem;
}