
  
.install-app-btn-container {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 5vw;
    height: 5vw;
    background-color: var(--colour-secondary);
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
    box-shadow: 0rem .1rem 1rem rgba(0, 0, 0, .5);
  }
  

  
  