.button-conn-container {
  display: grid;
  grid-template-columns: 70% 30%;
  cursor: pointer;
}

.connect-button-svg-container {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 2rem;
}

.connect-button-svg-container svg {
  /* width: 80%; */
  height: 80%;
}

.connect-silhouet-svg-container {
  padding-top: 0.5rem;
  display: grid;
  align-items: end;
}

.connect-spinner-svg-container {
  display: grid;
  align-items: center;
  justify-items: center;
}

.connect-spinner-svg-container svg {
  width: 80%;
}
