.calibration-screen-container {
    display: grid;
    grid-template-areas: "marty-image" "middle-text" "bottom-buttons";
    grid-template-rows: 1.2fr .7fr 1fr;
    height: 95%;
}

.calibration-screen-marty-image-container {
    grid-area: marty-image;
    display: grid;
    justify-items: center;
    align-items: end;
}

.calibration-screen-marty-image-container img {
    width: 25%;
}


.calibration-screen-middle-text-container {
    grid-area: middle-text;

}

.calibration-screen-middle-text-title {
    font-size: 2.5rem;
    font-family: "BigNoodleTitling Regular";
    margin: 2rem 0;
}

.calibration-screen-middle-text-subtitle {
    font-size: 1.8rem;
    color: var(--colour-HINT_GREY);
    margin-bottom: 1rem;
}


.calibration-screen-bottom-buttons-container {
   align-self: end; 
}  


.calibration-screen-bottom-buttons-container > div {
    margin-bottom: 1rem;
}
