.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 70vw;
  max-height: 400px;
  overflow: scroll;
  margin: 0 auto;
}

.button{
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: var(--colour-primary);
  color: white;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.title {
  margin-bottom: 1rem;
}

.icon {
  /* width: 50px;
  height: 50px; */
}

.content h1 {
  color: #444;
  margin-bottom: 20px;
}

.content p {
  margin-bottom: 10px;
}

.content strong {
  color: var(--colour-primary);
  font-weight: bold;
  font-family: "Lato Bold"
}
