.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
}

.iframe {
  height: 100%;
  width: 100%;
  border: none;
  overflow: hidden;
}
