.home-screen-container {
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    position: relative;
    background-image: linear-gradient(to right, var(--colour-LIGHT_BLUE), var(--colour-primary));
}

/* 
.main-main-content-container {
    padding: 0;
} */

.home-screen-background-svg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/marty_text_img.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
}


@media (min-aspect-ratio: 1.8/1) {
    .home-screen-background-svg-container {
        width: 80%;
    }
}


@media (min-aspect-ratio: 2.2/1) {
    .home-screen-background-svg-container {
        width: 60%;
    }
}

@media (min-aspect-ratio: 2.6/1) {
    .home-screen-background-svg-container {
        width: 40%;
    }
}

.home-screen-background-svg-container svg {
    background-image: url('../../assets/marty_text_img.svg');
    background-position: right center;
    background-size: contain;
}


/* .home-screen-cartoon-svg-container {
    padding: 1rem;
    display: flex;
    justify-content: center;
}


.home-screen-cartoon-svg-container svg{
    width: 90%;
    height: 90%;
} */