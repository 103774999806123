.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.modalHeader {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.warningIcon {
  color: #ff9800;
  margin-right: 10px;
}

.modalBody {
  margin-top: 10px;
  margin-bottom: 10px;
}

.modalFooter {
  text-align: right;
}

.disconnectButton {
  background-color: var(--colour-RED);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
