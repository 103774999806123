.infoContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #0077b5;
  margin-bottom: 16px;
}

.paragraph {
  font-size: 16px;
  color: #000000;
  padding: 0;
  padding-bottom: 4px;
}

.paragraph b {
  text-decoration: underline;
}
