.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 90vw;
  max-height: 600px;
  overflow: scroll;
  margin: 0 auto;
}

.form {
  width: 60%;
}

.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 16px;
  color: #000000;
}

.input {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.disabledInput {
  border: none;
  background-color: transparent;
  font: inherit; 
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.submitButton,
.cancelButton,
.addMoreRobotsButton {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.submitButton {
  background-color: var(--colour-GREEN);
  color: white;
}

.cancelButton {
  background-color: var(--colour-RED);
  color: white;
}

.addMoreRobotsButton {
  background-color: var(--colour-SCREENFREE_BLUE);
  color: white;
}

.submitButton:hover,
.cancelButton:hover,
.addMoreRobotsButton:hover {
  opacity: 0.8;
}

.scanningForMoreRobots { 
    display: grid;
    align-items: center;
    justify-items: center;
    height: 40px;
}

/* Coming soon button */
.comingSoonButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: var(--colour-SCREENFREE_PURPLE);
  border: none;
  border-radius: 30px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
}

.comingSoonButton:hover {
  background-color: var(--colour-PINK_LED);
  transform: translateY(-3px);
}

.comingSoonButton:active {
  transform: translateY(-1px);
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.15);
}

.afterRegistrationInfo {
  margin-top: 1rem;
}

.afterRegistrationInfoParagraph {
  font-size: 10px;
}