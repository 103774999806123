.info-field-container {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "icon rightside";
  column-gap: 1rem;
  padding-left: 1rem;
  padding-bottom: .5rem;
}

.info-field-rightside-container{
  grid-area: rightside;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "title button" "subtitle button";
  row-gap: .5rem;
  padding-right: 1rem;
  border-bottom: 1px solid var(--colour-GREY_3);
}


.info-field-icon-container {
  grid-area: icon;
  align-self: center;
}

.info-field-title {
  grid-area: title;
  justify-self: start;
  font-size: 1.8rem;
  color: var(--colour-BLACK)
}

.info-field-subtitle {
  grid-area: subtitle;
  justify-self: start;
  font-size: 1.4rem;
  color: var(--colour-GREY_3);
}

.info-field-button-text {
  grid-area: button;
  align-self: center;
  cursor: pointer;
  font-size: 1.8rem;
  color: red;
}
