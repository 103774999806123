.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 70vw;
  max-height: 400px;
  overflow: scroll;
  margin: 0 auto;
}

.buttonsContainer {
  display: flex;
  width: 100%;
  column-gap: 2rem;
}

.button{
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: white;
  width: 100%;
}

.registerButton {
  background-color: var(--colour-GREEN);
}

.cancelButton {
  background-color: var(--colour-RED);
}

.disabledButton {
  background-color: #ccc;
  cursor: not-allowed;
}