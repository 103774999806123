.help-screen-container {
    display: grid;
    row-gap: 1rem;
    justify-items: center;
    padding: 2rem;
    padding-top: 4rem;
    font-family: "Lato Regular";
}

.help-screen-logo-container {

}

.help-screen-main-text-container {
    text-align: center;
}

.help-screen-buttons-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1rem;
}
