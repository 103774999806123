.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1); /* Almost transparent */
  z-index: 8;
}

.modal {
  overflow: hidden;
  z-index: 9;
  padding-bottom: 45px;
  border: 2px solid black;
}

.modalHeader {
  cursor: move;
  background-color: #2f3640;
  padding: 10px;
  border-bottom: 1px solid #57606f;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
}

.modalTitle {
  text-align: center;
  color: #f1f2f6;
  font-size: 18px;
  font-weight: bold;
}

.modalControls {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.controlBtn {
  cursor: pointer;
  color: #f1f2f6;
  transition: color 0.2s;
}

.controlBtn:hover {
  color: #dfe4ea;
}


.modalOverlay {
  background-color: rgba(0, 0, 0, 0) !important;
}

.modalBody {
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.modalBody::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.rndHandle {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the color and opacity as needed */
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: 2;
}

.resizableHandle-tl,
.resizableHandle-tr,
.resizableHandle-bl,
.resizableHandle-br {
  z-index: 2;
}

.resizableHandle-tl {
  top: -6px;
  left: -6px;
  cursor: nwse-resize;
}

.resizableHandle-tr {
  top: -6px;
  right: -6px;
  cursor: nesw-resize;
}

.resizableHandle-bl {
  bottom: -6px;
  left: -6px;
  cursor: nesw-resize;
}

.resizableHandle-br {
  bottom: -6px;
  right: -6px;
  cursor: nwse-resize;
}

.resizableHandle-t,
.resizableHandle-b {
  left: 50%;
  transform: translateX(-50%);
}

.resizableHandle-t {
  top: -6px;
  cursor: ns-resize;
}

.resizableHandle-b {
  bottom: -6px;
  cursor: ns-resize;
}

.resizableHandle-l,
.resizableHandle-r {
  top: 50%;
  transform: translateY(-50%);
}

.resizableHandle-l {
  left: -6px;
  cursor: ew-resize;
}

.resizableHandle-r {
  right: -6px;
  cursor: ew-resize;
}
