.wifi-list-container {
    display: grid;
}

.wifi-list-spinner-container {
    width: 20px;
    justify-self: center;
    align-self: center;
    display: inline-block;
}

.wifi-list-loading-msg {
    margin-top: 5rem;
    margin-right: 3rem;
    display: inline;
}

.wifi-list-scan-button-container {
    justify-self: start;
    transform: scale(.7);
}