.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 90vw;
  max-height: 600px;
  overflow: scroll;
  margin: 0 auto;
}

.reportIssueContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.reportIssue {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.reportIssueButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  padding: 20px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  cursor: pointer;
  text-align: center;
}

.reportIssueButton:hover {
  transform: translateY(-10px);
}

.reportIssueButton h4 {
  margin: 10px 0;
  color: #333;
}

.reportIssueButton p {
  color: #777;
}

.reportIssueButton svg {
  color: #2196f3;
  width: 50px;
  height: 50px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
