.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
}

.deviceList {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.deviceItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.removeButton {
  margin-left: 10px;
  cursor: pointer;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 1rem;
}


.submitButton,
.cancelButton,
.addAnotherRobotButton,
.addRobotsBtButton,
.addRobotsQRButton {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}


.submitButton {
  background-color: #4caf50;
  color: white;
}

.cancelButton {
  background-color: #f44336;
  color: white;
}

.addAnotherRobotButton {
  background-color: #2196f3;
  color: white;
}

.scannerContainer {
  width: 400px;
  height: 400px;
}

.addRobotsBtButton {
  background-color: #2196f3;
  color: white;
}


.addRobotsQRButton {
  background-color: #2196f3;
  color: white;
}