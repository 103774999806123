.diagnostics-screen-container {
    height: 100%;
    width: 100%;
}

.diagnostics-screen-list-container {
    padding: 2rem;
    margin-left: 2rem;
}

.diagnostics-screen-list-container li {
    color: var(--colour-GREY_3);
    margin-bottom: 1rem;
}