.update-marty-name-container {
    display: grid;
    padding: 0 1rem;
    row-gap: 1rem;
}

.update-marty-name-title {
    font-size: 1.4rem;
}

.update-marty-name-input-field {
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
    border: 1px solid var(--colour-MAIN_BLUE);
    border-radius: 5px;
}

.update-marty-name-input-field:focus {
    outline: none;
}

.update-marty-button-container {
    width: 80%;
    margin-top: 1rem;
    justify-self: center;
}