.wifi-conf-step-one-container {
    padding: 1rem;
    height: 100%;
    display: grid;
    align-items: start;
}

.wifi-conf-step-one-title {
    font-size: 2rem;
    font-family: "Lato Bold";
    margin-bottom: .7rem;
}

.wifi-conf-step-one-subtitle {
    color: var(--colour-GREY);
}


.wifi-conf-step-one-bottom-buttons-container {
    align-self: end;
    display: grid;
    row-gap: 1rem;
}

.wifi-conf-step-one-a-ssid-title {
    font-size: 1.4rem;
    margin-top: 5rem;
}

.wifi-conf-step-one-a-ssid-input-field {
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
    border: 1px solid var(--colour-MAIN_BLUE);
    border-radius: 5px;
}

.wifi-conf-step-one-a-ssid-input-field:focus {
    outline: none;
}

.wifi-conf-step-two-ssid-name {
    font-size: 2rem;
    color: var(--colour-BLACK);
}