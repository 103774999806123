.addon-item-container {
}

.addon-item-inner-container {
  display: grid;
  grid-template-columns: 1fr min-content;
  border-bottom: 1px solid var(--colour-GREY_4);
  margin-bottom: 1rem;
  cursor: pointer;
}

.addon-item-name-container {
  display: grid;
  align-items: center;
  font-size: 1.4rem;
  color: var(--colour-BLACK);
}

.addon-item-name {
  padding: 0;
  margin: 0;
}

.addon-item-configuration-container {
  display: grid;
  grid-template-areas:
    "type serial-number version"
    "name-input name-input name-input"
    "update-button update-button delete-button";
  row-gap: 1rem;
}

.addon-item-type {
  grid-area: type;
}

.addon-item-serial-number {
  grid-area: serial-number;
  justify-self: center;
}

.addon-item-version {
  grid-area: version;
  justify-self: end;
}

.addon-item-name-input {
  grid-area: name-input;
}

.addon-item-update-name-button {
  grid-area: update-button;
  width: 80%;
}

.addon-item-delete-button {
  grid-area: delete-button;
}
