:root {
    /* your colours here */
}

.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--colour-GREY_2);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px var(--colour-BLACK_025);
}

.formLabel {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--colour-BLACK);
}

.formInput,
.formTextarea {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--colour-TRUE_WHITE);
    box-shadow: 0 0 5px var(--colour-BLACK_025);
    margin-bottom: 20px;
    resize: vertical;
    max-height: 300px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.inputTitle {
    color: var(--colour-BLACK);
    font-size: 2.4rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    text-align: justify;
    text-align: center;
}

.inputDescription {
    color: var(--colour-BLACK);
    font-size: 1.6rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    text-align: justify;
}

.formSubmitButton {
    background-color: var(--colour-primary); 
    color: var(--colour-TRUE_WHITE);
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
}

.formSubmitButtonDisabled {
    background-color: var(--colour-GREY_2); 
    color: var(--colour-BLACK);
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
}

.formSubmitButton:hover {
    background-color: var(--colour-AQUA_BLUE_05); 
    color: var(--colour-BLACK);
}

.formCancelButton {
    background-color: var(--colour-RED);
    color: var(--colour-TRUE_WHITE);
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
}

.formCancelButton:hover {
    background-color: var(--colour-BLACK);
    color: var(--colour-TRUE_WHITE);
}