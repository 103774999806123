.help-section-container {
    display: grid;
    grid-template-columns: .4fr .6fr;
    align-items: center;
    height: 100%;
}

.help-section-help-svg-container {
    cursor: pointer;
}

.help-section-help-svg-container svg {
    width: 80%;
}

.help-section-configure-svg-container {
    cursor: pointer;
}