.connect-button-container {
    background-color: var(--colour-BLUE_LIGHT_3);
    border-radius: 5px;
    /* margin: 1rem; */
}



/* Minimum aspect ratio */
@media (min-aspect-ratio: 8/5) {
    .connect-button-container {
        margin: 0;
    }
  }