.labeled-info-container {
}

.labeled-info-label {
  font-size: 1.2rem;
  color: var(--colour-BLACK);
}

.labeled-info-info {
  color: var(--colour-BLACK);
  font-size: 1.5rem;
}
