.help-screen-text-title {
    font-family: "BigNoodleTitling Regular";
    font-size: 2.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.help-screen-text-paragraph {
    color: var(--colour-GREY_3);
}

.help-screen-text-bold-span {
    color: var(--colour-BLACK);
    font-family: "Lato Bold";
}


.help-screen-bottom-paragraph {
    color: var(--colour-BLACK);
    padding-top: 2rem;
}