.nav-layout-container {
  height: 100%;
}

.nav-layout-go-back-btn {
  cursor: pointer;
  position: absolute;
  margin: 3px;
  border-radius: 50%;
  background-color: white;
  left: 0;
  top: 0;
  width: 2.5vw;
  height: 2.5vw;
  opacity: 0.4;
  transition: all .2s;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-layout-go-back-btn:hover {
  width: 4vw;
  height: 4vw;
  opacity: 1;
}
