.nav-menu-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  
  display: grid;
  grid-template-areas: "connect-button" "nav-buttons" "help-section";
  grid-template-rows: .3fr 1fr min-content;
  padding: 1rem;
  padding-top: 2rem;

  border-right: 2px solid var(--colour-BLUE_LIGHT_2);

  background-color: var(--colour-BLUE_LIGHT_1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav-menu-container::-webkit-scrollbar,
.nav-menu-nav-buttons-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav-menu-container,
.nav-menu-nav-buttons-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.nav-menu-connect-button-container {
  grid-area: connect-button;
  padding: 2rem 1rem;
  align-self: end;
}

.nav-menu-nav-buttons-container {
  grid-area: nav-buttons;
  border-top: 2px solid var(--colour-BLUE_LIGHT_2);
  border-bottom: 2px solid var(--colour-BLUE_LIGHT_2);
  padding-top: 2rem;
  overflow-y: scroll;
}

.nav-menu-help-section-container {
  grid-area: help-section;
  padding: 1rem;
}
