@font-face {
  font-family: 'BigNoodleTitling Regular';
  font-style: normal;
  font-weight: normal;
  src: local('BigNoodleTitling Regular'),
    url('../fonts/big_noodle_titling.woff') format('woff');
}

@font-face {
  font-family: 'BigNoodleTitling Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('BigNoodleTitling Oblique'),
    url('../fonts/big_noodle_titling_oblique.woff') format('woff');
}

@font-face {
  font-family: 'Lato Regular';
  font-weight: normal;
  src: url('../fonts/Lato-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Lato Bold';
  font-weight: bold;
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
}

p, div {
  font-family: "Lato Regular";
}

h1, h2, h3, h4, h5, h6 {
  font-family: "BigNoodleTitling Regular";
}