.labeled-input-conatiner {

}

.labeled-input-label {
  font-size: 1.2rem;
  color: var(--colour-BLACK);
  margin-bottom: .5rem;
}

.labeled-input-input {
  width: 100%;
  padding: 1rem;
  font-size: 1.4rem;
  border: 1px solid var(--colour-MAIN_BLUE);
  border-radius: 5px;
}
