.configuration-screen-container {
    margin-top: 20px;
    width: 100%;

    display: grid;
    row-gap: 2rem;
    padding: 0 1rem;
}


.configuration-screen-calibration-container {
}

.configuration-screen-updates {
    width: 80%;
    justify-self: center;
}

.marty-version-text {
    margin-top: 5px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #000000;
}