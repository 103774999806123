.analytics-consent-modal-container {
  /* width: 70vw; */
  /* height: 70vh; */
  display: grid;
}

.analytics-consent-modal-info-container {
  margin-top: 3rem;
  text-align: center;
}

.analytics-consent-modal-info-paragraph {
  color: var(--colour-GREY);
  margin-bottom: 2rem;
}

.analytics-consent-modal-buttons-container {
  align-self: end;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "yes yes" "no info";
  column-gap: 2rem;
  row-gap: 2rem;
}

.analytics-consent-modal-buttons-yes-button {
  grid-area: yes;
}

.analytics-consent-modal-buttons-no-button {
  grid-area: no;
}

.analytics-consent-modal-buttons-info-button {
  grid-area: info;
}
