/* User selector */
.userSelectorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .userSelector {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  
  .userSelectorButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    padding: 20px;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    cursor: pointer;
    text-align: center;
  }
  
  .userSelectorButton:hover {
    transform: translateY(-10px);
  }
  
  .userSelectorButton h4 {
    margin: 10px 0;
    color: #333;
  }
  
  .userSelectorButton p {
    color: #777;
  }
  
  .userSelectorButton svg {
    color: #2196f3;
    width: 50px;
    height: 50px;
  }
  