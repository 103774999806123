.infoContainer {
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
    padding: 16px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #0077B5;
    margin-bottom: 16px;
  }
  
  .paragraph {
    font-size: 16px;
    color: #000000;
    margin-bottom: 16px;
  }

  .paragraph b {
    text-decoration: underline;
  }