
.svg-image-button-container {
    display: grid;
    grid-template-areas: "image . title" "image . subtitle";
    grid-template-columns: 10% .5fr 1fr;
    background-color: var(--colour-GREY_2);
    color: var(--colour-BLACK);
    text-align: center;
    padding: 1rem 0;
    font-family: "BigNoodleTitling Regular";
    font-size: 2rem;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 4px;
} 

.svg-image-button-container-no-sub {
    display: grid;
    grid-template-areas: "image . title";
    grid-template-columns: 10% .5fr 1fr;
    background-color: var(--colour-GREY_2);
    color: var(--colour-BLACK);
    text-align: center;
    font-family: "BigNoodleTitling Regular";
    font-size: 2rem;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
} 

.svg-image-button-title {
    grid-area: title;
    text-align: start;
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-size: 2rem;
    align-self: end;
    font-family: "BigNoodleTitling Regular";
} 

.svg-image-button-title-no-sub {
    grid-area: title;
    text-align: start;
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-size: 2rem;
    font-family: "BigNoodleTitling Regular";
} 


.svg-image-button-subtitle {
    grid-area: subtitle;
    text-align: start;
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    color: var(--colour-GREY_3);
} 

.svg-image-button-image-container {
    grid-area: image;
    padding-left: 1rem;
} 

